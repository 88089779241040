import React, { useState, useEffect } from "react";
import MailchimpSubscribe, { EmailFormFields } from "react-mailchimp-subscribe";
import styled from "styled-components";
import { FiSend } from "react-icons/fi";

interface Props {
  status: "error" | "success" | "sending" | null;
  message: string | Error;
  onValidated: (data: EmailFormFields) => void;
}

const SubscribeForm: React.FC = () => {
  return (
    <MailchimpSubscribe
      url={process.env.GATSBY_MAILCHIMP_URL || ""}
      render={({ subscribe, status, message }) => (
        <SubscribeFormInner
          status={status}
          message={message ?? ""}
          onValidated={(formData) => subscribe(formData)}
        />
      )}
    />
  );
};

export default SubscribeForm;

const SubscribeFormInner: React.FC<Props> = ({
  status,
  message,
  onValidated,
}) => {
  let email: HTMLInputElement | null;

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  };

  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    const msg = typeof message === "string" ? message : message.message;
    if (msg.includes("already subscribed")) {
      setErrorMessage("Already subscribed!");
    } else if (msg.includes("address is invalid")) {
      setErrorMessage("Email address is invalid!");
    } else if (status === "error") {
      setErrorMessage("Error subscribing!");
    } else {
      setErrorMessage("");
    }
  }, [status, message]);

  return (
    <Wrapper>
      <h2>Subscription to our newsletter</h2>
      <p>Enter your email to stay updated.</p>
      <StatusMessage>
        {status === "sending" && <p style={{ color: "#000" }}>Sending...</p>}
        {status === "error" && (
          <p style={{ color: "red" }}>
            {/* {typeof message === "string" ? message : message.message} */}
            {errorMessage}
          </p>
        )}
        {status === "success" && (
          <p style={{ color: `var(--color-orange)` }}>Subscribed!</p>
        )}
      </StatusMessage>
      <Form onSubmit={submit}>
        <Input
          ref={(node) => (email = node)}
          type="email"
          placeholder="Your email"
          required
        />
        <SendButton type="submit" aria-label="Submit">
          <FiSend color={"#fff"} />
        </SendButton>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 var(--gutter);
  background: var(--color-light-grey);
  display: flex;
  flex-direction: column;
  text-align: center;
  place-items: center;
  justify-content: center;

  h2 {
    margin-top: 60px;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 40px;
  }
`;

const StatusMessage = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  text-align: center;
  height: 70px;
  transform: translate(-50%, 0);
`;

const Form = styled.form`
  height: 44px;
  display: flex;
  place-items: center;
  justify-content: center;
  margin-bottom: 80px;

  @media (max-width: 520px) {
    width: 100%;
  }
`;

const Input = styled.input`
  display: block;
  height: 100%;
  width: 400px;
  border: 2px solid var(--color-orange);
  border-radius: 99px 0px 0px 99px;
  padding-left: 2rem;

  &:focus {
    outline: none;
  }
  &:focus,
  &:hover {
    border-color: var(--color-orange-hovered);
  }

  @media (max-width: 520px) {
    flex: 1;
  }
`;

const SendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  height: 100%;
  width: 56px;
  background: var(--color-orange);
  border: 0;
  padding: none;
  border-radius: 0px 99px 99px 0px;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    background: var(--color-orange-hovered);
  }
  svg {
    width: 26px;
    height: 26px;
  }
`;
